<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="showAccountModal"
    >   
        <CForm>
            <CRow>
              <CCol sm="4">
                <CSelect
                    label="Tipo Persona: "
                    :isValid="checkIfValid('type')"
                    :value.sync="$v.form.type.$model"
                    :options="[{value: 'physical', label: 'Física'}, {value: 'moral', label: 'Moral'}]"
                    placeholder=""
                    invalidFeedback="Este campo es requerido."
                />
              </CCol>
              <CCol sm="8">
                <CInput
                    :isValid="checkIfValid('rfc')"
                    :value.sync="$v.form.rfc.$model"
                    label="RFC"
                    placeholder=""
                    invalidFeedback="El RFC es un campo obligatorio."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                  :isValid="checkIfValid('name')"
                  :value.sync="$v.form.name.$model"
                  label="Nombre o Razón Social"
                  placeholder=""
                  invalidFeedback="El nombre o razón social es un campo obligatorio."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CSelect
                    label="Régimen Fiscal: "
                    :isValid="checkIfValid('regimen')"
                    :value.sync="$v.form.regimen.$model"
                    :options="regimensOptions"
                    placeholder=""
                    invalidFeedback="Este campo es requerido."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="8">
                <CSelect
                    label="Estado: "
                    :isValid="checkIfValid('state')"
                    :value.sync="$v.form.state.$model"
                    :options="statesOptions"
                    placeholder=""
                    invalidFeedback="Este campo es requerido."
                />
              </CCol>
              <CCol sm="4">
                <CSelect
                    label="Zona Horaria: "
                    :value.sync="$v.form.huso_horario.$model"
                    :options="[{value: 'CENTRO', label: 'Centro'}, {value: 'PACIFICO', label: 'Pacífico'}, {value: 'NOROESTE', label: 'Noroeste'}, {value: 'SURESTE', label: 'Sureste'}]"
                    placeholder=""
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="singup" :color="color">Registrar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"
import account_ws from '../../services/account';
import general_ws from '../../services/general';
import login from '../../services/login';
import store from '../../store'

export default { 
  name: 'NewAccountModal',
  props: {

  },
  
  data () {
    return {
      showAccountModal: false,
      title: "Nueva Cuenta", 
      color: "info",
      isEdit: false,
      submitted: false,
      is_finished: false,
        form: {
            type: 'physical',
            rfc: '',
            name: '',
            regimen: '626',
            state: 'JAL',
            huso_horario: 'CENTRO',
        },
        rfc_error: 0,
        email_error: 0,
        states: [],
        statesOptions: [],
        regimens: [],
        regimensOptions: [],
    }
  },
  mounted: async function() {
    let response = await general_ws.getRegimens(); 

    if(response.type == "success"){
      this.regimens = response.data;
    }

    let response2 = await general_ws.getStates(); 

    if(response2.type == "success"){
      this.states = response2.data;
    }

    this.regimensOptions = await this.$parseSelectOptionsOrdered(this.regimens, "code", "code:name");
    this.statesOptions = await this.$parseSelectOptionsOrdered(this.states, "code", "name");
  },
  methods: {
    storeModal () {
      this.showAccountModal = true;
      this.color = "info";
      this.title = 'Nueva Cuenta';
      this.isEdit = false;
      this.submitted = false;

      this.form = this.getEmptyForm();
    },
    async singup () {
      this.$v.$touch();

      if (this.isValid && await this.validateRFC(this.form.rfc)) {
          this.submitted = true;

          let response = await login.storeOwnerAccount(this.form);
          
          if(response.type == "success"){
            this.showToast(response.type, response.message);

            this.$emit("store", response.data );

            this.closeModal();
          }
          else{
            this.showToast(response.type, response.message);
          }
      }
    },
    back(){
      router.push("login");
    },
    async validateRFC (rfc) {
        let response = await login.validateRFC(rfc);
        
        if(response.type == "success" && response.data == 1){
            this.showToast("error", "El RFC ya fue registrado anteriormente");
            this.form.rfc = "";

            return false;
        }

        return true;
    },
    checkIfValid (fieldName) {
        const field = this.$v.form[fieldName]
        if (!field.$dirty) {
            return null
        }
        
        return !(field.$invalid)
    },
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    loading(){
        store.commit('loading');
    },
    loaded(){
        store.commit('loaded');
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        this.$emit("store", this.form );

        this.closeModal();
      }
    },
    closeModal () {
      this.showAccountModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          type: 'physical',
          rfc: '',
          name: '',
          regimen: '626',
          state: 'JAL',
          huso_horario: 'CENTRO'
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    }
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      type: {
          required
      },
      rfc: {
          required
      },
      name: {
          required
      },
      regimen: {
          required
      },
      password: {},
      state: {
          required
      },
      huso_horario: {},
  }
  }
}
</script>