<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink ref="searching">
        <span style="height: 20px;"><CIcon :height="20" name="cil-group"  /></span>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Mis Cuentas</strong>
      <span v-if="partners.length + owners.length > 5" >
      <br /><br />
      <CInput
        class="centered"
        v-model="search"
        :lazy="false"
        placeholder=""
        @change="changeSearch"
      />
      </span>
    </CDropdownHeader>
    <CDropdownDivider v-if="partners.length > 0" />
    <CDropdownItem  v-if="partners.length > 0" @click="loginPartner(partner)" v-show="partner.fiscal.rfc.includes(search.toUpperCase()) || partner.fiscal.comercial_name.includes(search.toUpperCase())" v-for="(partner, index1) in partners" :key="'A'+index1">
      <CIcon name="cil-external-link" /> {{partner.fiscal.rfc}}&nbsp; - &nbsp;{{partner.fiscal.fiscal_name}}
    </CDropdownItem>
    <CDropdownDivider v-if="owners.length > 0" />
    <CDropdownItem  v-if="owners.length > 0" @click="loginOwner(owner)" v-show="owner.fiscal.rfc.includes(search.toUpperCase()) || owner.fiscal.comercial_name.includes(search.toUpperCase())" v-for="(owner, index2) in owners" :key="'B'+index2">
      <CIcon name="cil-external-link" /> {{owner.fiscal.rfc}}&nbsp; - &nbsp;{{owner.fiscal.comercial_name}}
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem @click="newAccountModal()">
      <CIcon name="cil-user-follow" /> NUEVA CUENTA
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Cerrar Sesión
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import router from '../router/index'
import store from '../store'
import account_ws from '../services/account';
import login from '../services/login';
import axios from 'axios'

export default {
  name: 'TheHeaderDropdownAccnt',
  components: { }, 
  data () {
    return { 
      rfc: '',
      name: '',
      deadline: '',
      deadline_days: 1000,
      deadline_date: '',
      stamps: 100,
      partners: [],
      owners: [],
      search: ''
    }
  },
  mounted: async function() {
    let response = await account_ws.getFiscalInfo(); 

    if(response.type == "success"){
      this.rfc = response.data.rfc;
      this.name = response.data.fiscal_name;
    }

    let response2 = await account_ws.getAccountDetails(); 

    if(response2.type == "success"){
      this.stamps = response2.data.stamps;
      this.deadline = response2.data.deadline;
      this.deadline_days = response2.data.deadline_days;
      this.deadline_date = response2.data.deadline_date;

      store.commit('setAccount', response2.data.account_id);
      store.commit('setStamps', response2.data.stamps);
      store.commit('setDeadlineDays', response2.data.deadline_days);
      store.commit('setDeadlineDate', response2.data.deadline_date);
    }

    let response3 = await account_ws.getPartners(); 

    if(response3.type == "success"){
      this.partners = response3.data;
    }

    let response4 = await account_ws.getOwnerAccounts(); 

    if(response4.type == "success"){
      this.owners = response4.data;
    }
  },
  methods: {
    loginPartner: async function(partner){
      let response = await login.loginPartner(partner.user.access_token);

      if(response.type == "success"){
        let data = response.data;

        localStorage.setItem("token", data.access_token);

        axios.defaults.headers.common['access-token'] = data.access_token;

        let analytics = await account_ws.getOnboarding(); 

        if(analytics.type == "success"){
          if(analytics.data === true){
            window.location.href = "/#/welcome"; window.location.reload(); return;
          }
        }

        window.location.href = "/#/dashboard";
        window.location.reload();
      }
      else{
        alert(response.message);
      }
    },
    loginOwner: async function(owner){
      let response = await login.loginOwner(owner.user.access_token, owner.id);

      if(response.type == "success"){
        let data = response.data;

        localStorage.setItem("token", data.access_token);

        axios.defaults.headers.common['access-token'] = data.access_token;

        let analytics = await account_ws.getOnboarding(); 

        if(analytics.type == "success"){
          if(analytics.data === true){
            window.location.href = "/#/welcome"; window.location.reload(); return;
          }
        }

        window.location.href = "/#/dashboard";
        window.location.reload();
      }
      else{
        alert(response.message);
      }
    },
    newAccountModal: function(){
      this.$emit("show");
    },
    refresh: async function(){
      let response = await account_ws.getPartners(); 

      if(response.type == "success"){
        this.partners = response.data;
      }

      let response2 = await account_ws.getOwnerAccounts(); 

      if(response2.type == "success"){
        this.owners = response2.data;
      }
    },
    logout: function(){
      this.$emit("update", this.form );
      localStorage.removeItem("token");
      store.commit('clear');
      router.push("login");
    },
    changeSearch: function(){
      console.log(this.search);
    },
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>